import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import {
	selectOrderLinesBigQuery,
	selectGraph,
	getGraphDataForType,
	getCustomerOrderLinesFromBigQuery,
	setOrderLinesBigQueryTimestamp,
	selectOrderLinesBigQueryOffset,
	selectOrderLinesBigQueryMaxResults,
	selectOrderLinesBigQueryTimestamp,
	setOrderLinesBigQueryOffset,
	selectSalesKeyFigures,
	getSalesKeyFiguresFromBigQuery,
	selectSettlementOrderLinesBigQuery
} from '@ameroservices-platform/loppe-boothownerui/app/main/apps/globalStore';
import { moneyFormatter } from '@ameroservices-platform/shared/utility/numbers/digits';
import ApexLineChart from '@ameroservices-platform/shared/ui-components/graphs/ApexLineChart';
import { getGraphOptions } from '@ameroservices-platform/loppe-boothownerui/app/main/apps/sale/graphOptions';
import { useTheme } from '@mui/material/styles';
import {
	Card,
	CardContent,
	CardHeader,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Pagination,
	Alert,
	AlertTitle
} from '@mui/material';
import FuseLoading from '@ameroservices-platform/shared/ui-components/FuseLoading';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SaleOrderLine from '@ameroservices-platform/loppe-boothownerui/app/main/apps/sale/SaleOrderLine';

function Sale() {
	const dispatch = useDispatch();
	const settlementOrderLines = useSelector(selectSettlementOrderLinesBigQuery);
	const orderLines = useSelector(selectOrderLinesBigQuery);
	const orderLinesTimestamp = useSelector(selectOrderLinesBigQueryTimestamp);
	const orderLinesMaxResults = useSelector(selectOrderLinesBigQueryMaxResults);
	const orderLinesOffset = useSelector(selectOrderLinesBigQueryOffset);
	const salesKeyFigures = useSelector(selectSalesKeyFigures);
	const graphDataYear = useSelector(state => selectGraph(state, 'year'));
	const graphDataMonth = useSelector(state => selectGraph(state, 'month'));
	const theme = useTheme();
	const flags = useFlags();

	React.useEffect(() => {
		const today = new Date();
		const daysAgo = moment(today).subtract(30, 'days').toDate();
		const yearAgo = moment(today).subtract(1, 'years').toDate();
		dispatch(getGraphDataForType(daysAgo.getTime(), today.getTime(), 'month'));
		dispatch(getGraphDataForType(yearAgo.getTime(), today.getTime(), 'year'));
		dispatch(getGraphDataForType(today.getTime(), today.getTime(), 'today'));
		dispatch(getGraphDataForType(-14182940000, today.getTime(), 'allTime'));
		dispatch(setOrderLinesBigQueryTimestamp(today.getTime()));
		dispatch(setOrderLinesBigQueryOffset(0));
	}, [dispatch]);

	React.useEffect(() => {
		dispatch(getCustomerOrderLinesFromBigQuery(undefined, orderLinesTimestamp, orderLinesOffset));
	}, [dispatch, orderLinesTimestamp, orderLinesOffset]);

	React.useEffect(() => {
		if (flags.loppeBoothownerSalesKeyFigures) {
			const today = new Date().getTime();
			dispatch(getSalesKeyFiguresFromBigQuery(undefined, today));
		}
	}, [dispatch, flags.loppeBoothownerSalesKeyFigures]);

	function handlePageChange(event, newPage) {
		dispatch(setOrderLinesBigQueryOffset(newPage * 10 - 10));
	}

	return (
		<div className="px-8 pt-40">
			<Card className="max-w-2xl md:mx-auto md:w-full mx-8 sm:p-20 mb-20 md:mb-40">
				<CardHeader title={'Dine salg'} className="bg-gray-200 sm:-mt-20 sm:-mx-20 px-36" />
				{flags.loppemodulDkVedligeholdelsesAdvarsel && (
					<Alert severity={'warning'} className={'rounded-none -mx-20'} variant={'filled'}>
						<AlertTitle>Vedligeholdelse</AlertTitle>
						Vi arbejder på at forbedre vores systemer. Derfor kan der forekomme ustabilitet og manglende
						data. Vi beklager ulejligheden.
					</Alert>
				)}
				<CardContent>
					{flags.loppeBoothownerSalesKeyFigures && (
						<div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-white mb-10">
							<div className="flex flex-col items-center justify-center border border-gray-200 p-4 rounded-lg ">
								<span className="text-xl font-semibold text-gray-800">
									{salesKeyFigures && salesKeyFigures.todaySales ? salesKeyFigures.todaySales : 0}
								</span>
								<span className="text-sm text-gray-500 mt-2">Solgte varer i dag</span>
							</div>

							<div className="flex flex-col items-center justify-center border border-gray-200 p-4 rounded-lg ">
								<span className="text-xl font-semibold text-gray-800">
									{salesKeyFigures && salesKeyFigures.totalSales ? salesKeyFigures.totalSales : 0}
								</span>
								<span className="text-sm text-gray-500 mt-2">Solgte varer i alt</span>
							</div>

							<div className="flex flex-col items-center justify-center border border-gray-200 p-4 rounded-lg ">
								<span className="text-xl font-semibold text-gray-800">
									{salesKeyFigures && salesKeyFigures.todayEarnings
										? moneyFormatter.format(salesKeyFigures.todayEarnings)
										: moneyFormatter.format(0)}
								</span>
								<span className="text-sm text-gray-500 mt-2">Standholder indtjening i dag</span>
							</div>

							<div className="flex flex-col items-center justify-center border border-gray-200 p-4 rounded-lg ">
								<span className="text-xl font-semibold text-gray-800">
									{salesKeyFigures && salesKeyFigures.totalEarnings
										? moneyFormatter.format(salesKeyFigures.totalEarnings)
										: moneyFormatter.format(0)}
								</span>
								<span className="text-sm text-gray-500 mt-2">Standholder indtjening i alt</span>
							</div>

							<div className="flex flex-col items-center justify-center border border-gray-200 p-4 rounded-lg ">
								<span className="text-xl font-semibold text-gray-800">
									{salesKeyFigures && salesKeyFigures.todayRevenue
										? moneyFormatter.format(salesKeyFigures.todayRevenue)
										: moneyFormatter.format(0)}
								</span>
								<span className="text-sm text-gray-500 mt-2">Omsætning i dag</span>
							</div>

							<div className="flex flex-col items-center justify-center border border-gray-200 p-4 rounded-lg ">
								<span className="text-xl font-semibold text-gray-800">
									{salesKeyFigures && salesKeyFigures.totalRevenue
										? moneyFormatter.format(salesKeyFigures.totalRevenue)
										: moneyFormatter.format(0)}
								</span>
								<span className="text-sm text-gray-500 mt-2">Omsætning i alt</span>
							</div>
						</div>
					)}

					<div className="flex flex-col mt-24">
						<ApexLineChart
							title={'Salg - seneste 30 dage'}
							seriesWithNameAndData={[{ data: graphDataMonth.data }]}
							height={200}
							options={getGraphOptions(graphDataMonth.labels, theme, 'DD-MM-YYYY')}
						/>
						<div className="mt-20"></div>
						<ApexLineChart
							title={'Salg - seneste 12 måneder'}
							seriesWithNameAndData={[{ data: graphDataYear.data }]}
							height={200}
							options={getGraphOptions(graphDataYear.labels, theme, 'MM-YYYY')}
						/>
					</div>
					<div className=" select-none px-4">
						<Table className="mt-24">
							<TableHead className="hidden md:table-header-group">
								<TableRow>
									<TableCell>&nbsp;</TableCell>
									<TableCell>Produkt navn</TableCell>
									{flags.showBoothAtSaleOnBoothOwnerUi && <TableCell>Stand</TableCell>}
									<TableCell>Stk.</TableCell>
									<TableCell>Pris</TableCell>
									<TableCell>Dato</TableCell>
									{flags.showExtendedDetailsForSalesOverviewOnLoppeBoothOwnerUi && (
										<TableCell>&nbsp;</TableCell>
									)}
								</TableRow>
							</TableHead>
							<TableBody>
								{orderLines &&
									orderLines.map((orderLine, index) => (
										<SaleOrderLine
											orderLine={orderLine}
											settlementOrderLines={settlementOrderLines}
											settlementOrderLine={settlementOrderLines?.find(
												so => so.loppeOrderUid === orderLine.orderUid
											)}
											index={index}
										/>
									))}
							</TableBody>
						</Table>
						{!orderLines && <FuseLoading />}
						{Math.ceil(orderLinesMaxResults / 10) >= 2 && (
							<div className={'flex justify-end mt-20'}>
								<Pagination
									count={Math.ceil(orderLinesMaxResults / 10)}
									shape="rounded"
									onChange={handlePageChange}
								/>
							</div>
						)}
					</div>
				</CardContent>
			</Card>
		</div>
	);
}

export default Sale;
